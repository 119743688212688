import React, { useRef } from 'react'
import "./Home.css"
import HeroBanner from "../../Components/HeroBanner/HeroBanner"
import HoursMapSection from '../../Components/HoursMapSection/HoursMapSection'
import CallNowSection from '../../Components/CallNowSection/CallNowSection'
import FacebookSection from '../../Components/FacebookSection/FacebookSection'
import Footer from '../../Components/Footer/Footer'
import { ContactUs } from '../../Components/ContactUs'
export default function Home() {

  const myRef = useRef(null);

  const scrollToComponent = () => {
    const offset = 0; // Adjust this value to scroll further down
    const elementPosition = myRef.current.getBoundingClientRect().top + window.pageYOffset;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  };


  return (
    <div>
      <HeroBanner scrollToComponent={scrollToComponent} />
      <HoursMapSection />
      <CallNowSection />
      <FacebookSection />
      <div ref={myRef} id="contact_form" className='contact-form__holder'>
      <ContactUs   />
      </div>
      <Footer   />
    </div>
  )
}
