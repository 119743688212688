import React from "react";
import "./Insurances.css";

export default function Insurances() {
  return (
    <div className="insurances__container">
      <h1>Accepted Insurances</h1>
      <hr />
      <p>Met life</p>
      <p>Excellus</p>
      <p>Cigna</p>
      <p>Guardian</p>
      <p>HEG</p>
      <i>If you don't see your insurance above, call for more info</i>
    </div>
  );
}
