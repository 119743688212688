import React from 'react'
import Insurances from '../Insurances/Insurances'
import "./FacebookSection.css"
export default function FacebookSection() {
  return (
    <div className='facebook-section'>
      <Insurances />
      <div>
      <iframe
      alt="facebook iframe"
      className="facebook-iframe"
      title="facebook"
      src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FDrMarkBalonek%2F&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=true&show_facepile=false&appId=185212794884923"
      width="340"
      height="400"
      // style="border:none;overflow:hidden"
      // scrolling="no"
      allow="encrypted-media"
      ></iframe>
      </div>
    </div>
  )
}
