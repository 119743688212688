import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";

// const bizSdk = require("facebook-nodejs-business-sdk");

// const accessToken =
//   "EAAE8Tg87CVsBAGclxFlneol7DXyVZBDNgUFdf0GNINS4FO6yxPQaDuJlE4CrLmAAtbYrAAElTWUktydkwDVgy3NhDuRPd01blcgsyVEJ4HcaEqSrU3ZBe5JAvCoX4358rmPjLT1XzTSjI48yd6vPtIOULIhBXkpSoM7fGkHKUa7XIka2iZB";
// const accountId = "act_{{process.env.REACT_APP_FB_ACCOUNT_ID}}";

// const FacebookAdsApi = bizSdk.FacebookAdsApi.init(accessToken);
// const AdAccount = bizSdk.AdAccount;
// const Campaign = bizSdk.Campaign;

// const account = new AdAccount(accountId);
// var campaigns;

// account
//   .read([AdAccount.Fields.name])
//   .then((account) => {
//     return account.getCampaigns([Campaign.Fields.name], { limit: 10 }); // fields array and params
//   })
//   .then((result) => {
//     campaigns = result;
//     campaigns.forEach((campaign) => console.log(campaign.name));
//   })
//   .catch(console.error);
// console.log("account", account);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
