import React from "react";
import "./HoursGrid.css";

export default function HoursGrid() {
  return (
    <div className="hours__container">
      <h1>Hours of Operation</h1>
      <hr />
      <p>Monday: 8AM-5PM</p>
      <p>Tuesday: 8AM-5PM</p>
      <p>Wednesday: 8AM-5PM</p>
      <p>Thursday: 8AM-5PM</p>
      <p>Friday: CLOSED</p>
      <p>Saturday: CLOSED</p>
      <p>Sunday: CLOSED</p>
    </div>
  );
}
