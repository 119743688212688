import React, { useRef, useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import "./ContactUs.css"

export const ContactUs = () => {
  const form = useRef();
  const [message, tyMessage] = useState("Send us an Email");
  const [isVisible, setIsVisible] = useState(true);
  const [isFormValid, setIsFormValid] = useState(false);
  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    message: ''
  });

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current, process.env.REACT_APP_PUBLIC_KEY)
      .then((result) => {
        console.log(result.text);
        tyMessage("Thanks, we'll be in touch!")
        document.querySelector('.card').classList.add('centered-card')
        setIsVisible(false);
      }, (error) => {
        console.log(error.text);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  useEffect(() => {
    const { user_name, user_email, message } = formData;
    setIsFormValid(user_name.trim() !== '' && user_email.trim() !== '' && message.trim() !== '');
  }, [formData]);

  return (
    <div className="card">
      <span className="title">{message}</span>
      {isVisible && (
        <form id="contact-form" ref={form} onSubmit={sendEmail} className="form">
          <div className="group">
            <input
              name="user_name"
              placeholder="‎"
              type="text"
              required
              onChange={handleInputChange}
            />
            <label htmlFor="name">Name</label>
          </div>
          <div className="group">
            <input
              name="user_email"
              placeholder="‎"
              type="email"
              id="email"
              required
              onChange={handleInputChange}
            />
            <label htmlFor="email">Email</label>
          </div>
          <div className="group">
            <textarea
              placeholder="‎"
              id="comment"
              name="message"
              rows="5"
              required
              onChange={handleInputChange}
            ></textarea>
            <label htmlFor="comment">Message</label>
          </div>
          <button
            type="submit"
            className={`g-recaptcha ${!isFormValid ? 'inactive-button' : ''}`}
            data-callback='onSubmit'
            data-action='submit'
            disabled={!isFormValid}
          >Submit</button>
        </form>
      )}
    </div>
  );
};
