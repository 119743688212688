import React from 'react'
import "./BlackButton.css"
export default function BlackButton(props) {

  const ButtonClick = () => {
    if (props.function === "learn-more") {
      console.log("Learn More")
      document.getElementById("call-now").scrollIntoView();
    } else if (props.function === "call") {
      // console.log("Call")
      window.open('tel:5854263050', '_self');
    }
  }

  return (
    <div>
      <button onClick={ButtonClick} className='black-button'>{props.text}</button>
    </div>
  )
}
