import React from 'react'
import "./HeroBanner.css"

import CutoutSmile from "../../assets/images/edit-smile.png"
import Logo_Tooth from "../../assets/images/Logo_Tooth.png"
import BlackButton from '../Buttons/BlackButton/BlackButton'

export default function HeroBanner(props) {
  return (
    <div className='hero-banner__container'>
    <div>
    <img className='hero-banner__logo' src={Logo_Tooth} alt='logo' />
    <h3 className='hero-banner__tagline'>Family Dentistry for over 25 years</h3>
    </div>
      <img className='hero-banner__cutout' src={CutoutSmile} alt='cutout' />
      <div className='hero-banner__right-side__container'>
        <h1>Your smile</h1>
        <h1 className='hero-banner__motto-second-line'>is our passion</h1>
        <h4 className='hero-banner__motto-third-line'>A better life starts with a beautiful smile</h4>
      <div className='hero-banner__button-holder'>
          <BlackButton text="Call Now" function="call" />
          
      </div>
      </div>
    </div>
  )
}
