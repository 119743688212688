import React from 'react'
import BlackButton from '../Buttons/BlackButton/BlackButton'
import "./CallNowSection.css"

import Image from "../../assets/images/dentist.png"

export default function CallNowSection() {
  return (
    <div id="call-now" className='call-now__container'>
      <div className='call-now__left'>
      <h3>At Balonek DDSPC, we are a small, family oriented general practice that tries to treat each patient in a warm and individualized way. From sleep apnea to extractions and crowns, patients are treated considering the whole person.</h3>
      <h4>"Get the Best Crowns in Town at Balonek DDSPC - Your Premier Dental Specialists"</h4>
      <BlackButton text="Call Now" function="call" />
      </div>
      <div className='call-now__right'>
      <img className='call-now-img' src={Image} alt="dentist" />
      </div>
    </div>
  )
}
