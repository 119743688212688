import React from "react";
// import Routes from "./Routes";
import "./App.css";

// import Header from "./Components/Header";
// import Footer from "./Components/Footer";
import Home from "./pages/Home/Home";

// import ReactPixel from "react-facebook-pixel";

// const advancedMatching = { em: 'some@email.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
// const options = {
//   autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
//   debug: false, // enable logs
// };
// ReactPixel.init(
//   process.env.REACT_APP_FB_ACCOUNT_ID,
//   // advancedMatching,
//   options
// );

// ReactPixel.pageView(); // For tracking page view
// ReactPixel.track(event, data); // For tracking default events. More info about standard events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
// ReactPixel.trackSingle('PixelId', event, data); // For tracking default events.
// ReactPixel.trackCustom(event, data); // For tracking custom events. More info about custom events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-events
// ReactPixel.trackSingleCustom('PixelId', event, data); // For tracking custom events.

function App() {
  // console.log("meta", process.env.REACT_APP_FB_ACCOUNT_ID);
  // useEffect(() => {
  //   ReactPixel.pageView();
  // }, []);

  return (
    <div className="App">
      <Home />
    </div>
  );
}

export default App;
