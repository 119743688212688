import React from 'react'
import HoursGrid from '../HoursGrid/HoursGrid'
import "./HoursMapSection.css"
import { ContactUs } from '../ContactUs'

export default function HoursMapSection() {
  return (
    <div className='hours-map__container'>
      <div className='hours'>
        <div className='hours-box'>
          <HoursGrid />
        </div>
      </div>
      <ContactUs   />
      <div className='map'>
        <div className='map-box'>
        <iframe
        className="mapiframe"
        title="map"
        width="100%"
        height="370"
        frameBorder="0"
        scrolling="no"
        marginHeight="0"
        marginWidth="0"
        src="https://maps.google.com/maps?width=100%25&amp;height=300&amp;hl=en&amp;q=513%20Beahan%20Road,%20Rochester,%20NY+(Balonek%20DDS%20PC)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
      ></iframe>
        </div>
      </div>
    </div>
  )
}
